/** 
 * Search page 
 * Utilises Office Fabric UI DetailsList component
 */

.govTeams-search {
  @media (max-width: $ms-screen-max-sm) {
    h2 {
      @include ms-fontSize-xl;
    }
  }

  .govTeams-searchBox {
    width: 100%;
    margin-bottom: 30px;
  }

  .tag {
    color: inherit !important;
    background-color: $ms-color-neutralLight;
    line-height: 1;
    display: inline-block;
    padding: 4px 8px;
    margin-right: 8px;
  }

  .govTeams-searchWarning {
    margin-top: 20px;
  }
}

.govTeams-searchPager {
  text-align: center;
  margin-top: 40px;

  .govTeams-searchPager--numbers {
    display: inline-block;
    margin: 0 15px;

    &.pagerInfo {
      display: none;

      @media (max-width: $ms-screen-max-md) {
        display: inline-block;
      }
    }

    &.pagerLinks {
      @media (max-width: $ms-screen-max-md) {
        display: none;
      }
    }
  }

  .govTeams-searchPager--number {
    @include ms-fontWeight-regular;
    color: inherit;
    display: inline-block;
    border-bottom: 1px solid transparent;
    padding: 0 8px 4px;

    &.selected {
      border-color: $ms-color-neutralSecondary;
    }
  }

  .govTeams-searchPager--next,
  .govTeams-searchPager--prev {
    @include ms-fontWeight-regular;
    color: inherit;

    i {
      vertical-align: -2px;
    }
  }

  .govTeams-searchPager--prev:first-child {
    margin-right: 8px;
  }

  .govTeams-searchPager--next:last-child {
    margin-left: 8px;
  }
}

.searchBox {
  width: 100%;
  display: flex;

  @media (min-width: $ms-screen-min-lg) {
    max-width: 530px;
  }

  .ms-SearchBox,
  .ms-TextField {
    flex: 10;
  }

  .ms-Button--primary {
    flex: 1 0 40px;
    white-space: nowrap;
  }
}

/**
 * Overrides to UI Fabric styles to make the entire column header is clickable for sorting
 */

.ms-DetailsHeader-cellTitle {
  width: 100%;

  .ms-DetailsHeader-cellName {
    flex: none;
  }
}

.memberPersona-callout {
  max-width: 400px;
  padding: 20px 0;

  .ms-Button--icon {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .memberPersona-delve {
    padding: 0 20px;
  }

  .memberPersona-delveLink {
    @include ms-fontWeight-semibold;
    display: inline-block;
    margin-top: 8px;
    margin-left: 88px;

    i {
      font-size: 12px;
      vertical-align: -1px;
    }
  }

  .memberPersona-contacts {
    padding: 0 20px;

    table td {
      padding: 0 10px 0 0;
      vertical-align: middle;
    }

    .ms-Link {
      width: 94%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ms-Button--action {
      height: auto;
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      i {
        margin-left: 0;
      }
    }
  }

  .memberPersona-message {
    padding: 0 60px 0 20px;
    position: relative;

    .ms-Button--icon {
      position: absolute;
      top: 0;
      right: 20px;

      i {
        color: $ms-color-neutralSecondary;
        font-size: 24px;
      }
    }
  }
}

.govTeams-searchBoxAlt {
  margin: 0 auto;
  // padding-right: 32px;
  position: relative;

  @media (min-width: $ms-screen-max-lg) {
    max-width: 415px;
  }

  .ms-SearchBox-iconContainer {
    width: 32px;

    i {
      opacity: 1;
    }
  }

  .ms-SearchBox-clearButton {
    margin-right: 32px;
  }

  .ms-SearchBox + .ms-Button--icon {
    color: $ms-color-white;
    background-color: $ms-color-themePrimary;
    position: absolute;
    top: 0;
    right: 0;
  }
}
