/**
 * Community panel
 * 
 */
.govTeams-communityPanel {
  h2 {
    margin-top: 0;
  }

  .panelBody {
    padding: 0 30px 30px;
  }
}

.communityInfo {
  @include ms-fontSize-l;

  i {
    font-size: 20px;
    
    .page-cell & {
      line-height: 1;
      width: 36px;
      height: 36px;
      background-color: $ms-color-neutralLight;
      // border: 1px solid $ms-color-neutralTertiary;
      border-radius: 50%;
      padding: 8px;
    }
  }

  tr {
    td:first-child {
      padding-right: 10px;
    }

    td {
      vertical-align: middle;
      padding-bottom: 5px;
    }
  }
  
  .counter {
    background-color: $ms-color-neutralLight;
    font-size: 14px;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    &.color-green {
      background-color: $ms-color-tealLight;
      color: $ms-color-white;
    }
  }

  .tag {
    @include ms-fontSize-m;
    background-color: $ms-color-neutralLight;
    line-height: 1;
    display: inline-block;
    margin-left: 4px;
    padding: 4px 8px;
  }
}

.communityOwners {
  min-height: 92px;
  max-height: 276px;
  overflow: hidden;

  &.expand {
    height: auto;
    max-height: none;
  }

  .communityOwners-list {
    .ms-List-cell {
      margin-top: 10px;
    }
  }

  .ms-Persona {
    height: 82px;
  }
}

.communityOwners-button {
  margin-top: 20px;
}
