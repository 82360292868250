.govTeams-notifications {
    display: inline-block;

    .ms-Button {
        min-width: 0;
        background-color: transparent;
        height: 50px;

        .ms-Button-icon {
            @include ms-fontSize-l;
        }
    }

    .ms-Button-textContainer {
        @include ms-fontSize-s;
        text-align: center;
        background-color: $ms-color-green;
        width: 18px;
        height: 18px;
        line-height: 0;
        flex-grow: 0;
        border-radius: 50%;
        margin-top: -18px;
        margin-left: -10px;
        padding: 0;
        border: 0;

        .ms-Button-label {
            line-height: 16px;
        }
    }
}

.govTeams-notification-panel {
  .notification-count {
    font-size: $ms-font-size-m;
    font-weight: $ms-font-weight-regular;
    float: right;
    margin-top: 12px;
  }


  .notification-item {
    .timestamp {
      float: right;
    }
  }

  .new {
    color: $ms-color-themePrimary;
  }
}

.linkNoHref {
  cursor: pointer;
}

.govTeams-notification {
  width: 360px;
  position: relative;
  top: 91px !important;
  right: 28px !important;
  left: auto !important;

  .govTeams-Callout {
    padding: 8px 8px 20px;
  }

  .govTeams-Callout--header {
    @include ms-clearfix;

    .ms-Button--icon {
      float: right;

      i {
        font-size: 10px;
      }
    }
  }

  .govTeams-Callout--body {
    @include ms-clearfix;

    .notification-message {
      width: 80%;
      padding-right: 10px;
      float: left;
    }

    .notification-time {
      @include ms-fontSize-s;
      text-align: right;
      width: 20%;
      float: right;
    }
  }
}
