/**
 * Footer styles
 */
.govTeams-Footer {
  @include ms-clearfix;
  font-weight: $ms-font-weight-semibold;
  color: $ms-color-white;   
  background-color: $govTeams-gray-dark;
  margin: 40px 0 0;
  padding: 10px 40px;

  ul {
    margin: 0;
    padding: 0;
    
    li {
      display: inline-block;
      margin: 0 0 0 50px;
      padding: 0;

      @media (max-width: $ms-screen-max-md) {
        margin: 0 0 0 20px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  a {
    @include ms-font-s;
    font-weight: $ms-font-weight-semibold;
    color: $ms-color-white;   

    &:hover,
    &:focus {
      color: $ms-color-white;   
    }
  }

  .footerLeft {
    float: left;

    @media (max-width: $ms-screen-max-md) {
      float: none;
    }
  }

  .footerRight {
    float: right;

    @media (max-width: $ms-screen-max-md) {
      float: none;
      margin-top: 10px;
    }
  }

  
}
