$actionsHeight: 40px;
$consoleHeight: 50px;

$fontFamily: 'Segoe UI', sans-serif;
$fontSize: 15px;

$headerPaddingBottom: 0;
$headerPaddingLeft: 8px;
$headerPaddingRight: 8px;
$headerPaddingTop: 8px;
$headerTotalHeight: 38px;

$actionTransition: .2s cubic-bezier(0,0,.5,1);

$card_narrow: 216px;
$card_normal: 320px;
$card_wide: 416px;

$card_borderWidth: 1px;
$card_padding: 8px;

$card_fontSize: 13px;

$separation: 8px;