/**
 * Variables for common values
 */
$govTeams-largeFont: xx-large;
$ms-brand-teams: rgb(85, 88, 175);
$govTeams-blue: #2b88d8;
$govTeams-blueDark: #193e6d;

$govTeams-yellow: #e39720;
$govTeams-teal: #1e8f8e;
$govTeams-maroon: #8b0041;
$govTeams-blueDarker: #20346d;

$govTeams-gray-dark: #151b26;
// $community-open: #0391a3;
// $community-closed: #444f6b;
// $community-hidden: #86569f;

$ms-color-themePrimary: $govTeams-blue;
$ms-color-themeDark: $govTeams-blueDark;

$aqua: rgb(5, 145, 163);
$violet: rgb(132, 106, 154);
$indigo: rgb(69, 80, 107);

$community-open: $aqua;
$community-closed: $indigo;
$community-hidden: $violet;

$spacing: 50px;

*[data-icon-name='OfficeLogo'] {
  color: $ms-color-red;
}

*[data-icon-name='Page'] {
  font-size: $govTeams-largeFont;
}

*[data-icon-name='WordLogo'] {
  color: $ms-color-blueMid;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='EdgeLogo'] {
  color: $ms-color-blueMid;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='OneNoteLogo'] {
  color: $ms-color-purple;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='ExcelLogo'] {
  color: $ms-color-green;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='PowerPointLogo'] {
  color: $ms-color-orangeLight;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='PDF'] {
  color: $ms-color-redDark;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='SharePointLogo'] {
  color: $ms-color-redDark;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='TextDocument'] {
  color: $ms-color-neutralPrimary;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='TextDocument'] {
  color: $ms-color-neutralPrimary;
  font-size: $govTeams-largeFont;
}

*[data-icon-name='TeamsLogo'] {
  color: $ms-brand-teams;
}

*[data-icon-name='OneNoteLogo'] {
  color: $ms-color-purple;
}

*[data-icon-name='PlannerLogo'] {
  color: $ms-color-green;
}

*[data-icon-name='PlannerLogo'] {
  color: $ms-color-green;
}

*[data-icon-name='Calendar'],
*[data-icon-name='DelveLogo'],
*[data-icon-name='OneDrive'],
*[data-icon-name='OutlookLogo'],
*[data-icon-name='SharepointLogo'],
*[data-icon-name='SharePointLogo'] {
  color: $ms-color-blue;
}

.govTeams-blue {
  background-color: $govTeams-blue;
}

.govTeams-blueDark {
  background-color: $govTeams-blueDark;
}

.govTeams-yellow {
  background-color: $govTeams-yellow;
}

.govTeams-teal {
  background-color: $govTeams-teal;
}

.govTeams-maroon {
  background-color: $govTeams-maroon;
}

.govTeams-blueDarker {
  background-color: $govTeams-blueDarker;
}

.community-open {
  &.page-header,
  .communityCard-header {
    background: rgb(5, 145, 163);
    // background: linear-gradient(
    //   90deg,
    //   rgba(5, 145, 163, 1) 38%,
    //   rgba(145, 214, 232, 1) 90%
    // );
    background: linear-gradient(90deg, #0591a3 38%, rgb(137, 204, 222) 100%);
  }

  .sponsoredBadge,
  .communityTypeBadge {
    color: $community-open;
  }
}

.community-closed {
  &.page-header,
  .communityCard-header {
    background: rgb(69, 80, 107);
    background: linear-gradient(
      90deg,
      rgba(69, 80, 107, 1) 6%,
      rgba(99, 145, 203, 1) 88%
    );
  }

  .sponsoredBadge,
  .communityTypeBadge {
    color: $community-closed;
  }
}

.community-hidden {
  &.page-header,
  .communityCard-header {
    background: rgb(132, 106, 154);
    // background: linear-gradient(
    //   90deg,
    //   rgba(132, 106, 154, 1) 23%,
    //   rgba(158, 155, 204, 1) 87%
    // );

    background: linear-gradient(90deg, #846a9a 33%, #9390c9 100%);
  }

  .sponsoredBadge,
  .communityTypeBadge {
    color: $community-hidden;
  }
}
