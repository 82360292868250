.govTeams-chatbot {
  display: inline-block;
  white-space: normal;
  text-align: left;

  .ms-Button  {
    background-color: transparent;
    height: 50px;
    padding: 0;
    min-width: auto;

    &:hover {
      background-color: transparent;
    }

    .ms-Button-icon {
      @include ms-fontSize-l;
    }
  }

  .govTeams-chatbot-panel {
    @include ms-fontColor-neutralDark;
    background-color: #ffffff;
    position: fixed;
    z-index: 1;
    bottom: 0;
    top: 30%;
    right: 0;
    left: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    pointer-events: auto;
    width: 450px;
    -webkit-box-shadow: -30px 0px 30px -30px rgba(0,0,0,0.2);
    box-shadow: -30px 0px 30px -30px rgba(0,0,0,0.2);

    &.off-screen {
      left: 100%;
    }

    .govTeams-chatbot-header {
      background-color: $ms-color-themeDark;
      display: table;
      border-spacing: 0;
      font-size: 0px;
      color: white;

      .govTeams-chatbot-header-leftAlign {
        width: 100%;
        text-align: left;
        padding: 0 18px;
        display: table-cell;
        vertical-align: middle;

        h2 {
          line-height: 50px;
          margin: 0;
        }
      }

      .govTeams-chatbot-header-rightAlign {
        text-align: right;
        padding: 0 18px;
        display: table-cell;
        vertical-align: middle;
      }
    }

    .govTeams-chatbot-panel-inner {
      position: absolute;
      top: 50px;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

