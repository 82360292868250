/**
 * Custom styles for GovTEAMS dashboard
 * Includes:
 * - Page layout
 * - Grid
 * - Community card
 */

@import '../../node_modules/@fluentui/react/dist/sass/_BrandIcon.scss';

.dashboard-header {
  display: flex;
  align-items: center;
  padding: ($spacing * 0.8) 0;

  @media (max-width: $ms-screen-max-md) {
    background-color: $ms-color-white;
    border-bottom: 1px solid $ms-color-neutralLight;
    margin-top: $spacing;
    padding: $spacing * 0.6;
  }

  h2 {
    @include ms-font-su;
    margin: 0 0 20px;
    flex: 2;

    @media (min-width: $ms-screen-min-xl) {
      display: flex;
      margin: 0;
    }
  }
}

.dashboard-quickLaunch {
  display: flex;

  .quickLaunch-item {
    color: inherit;
    display: flex;
    padding-right: 30px;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:last-child {
      padding-right: 0;
    }

    h3 {
      @include ms-fontWeight-semibold;
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .item-text {
      padding-left: 20px;

      @media (max-width: $ms-screen-max-xl) {
        display: none;
      }
    }

    .item-text-govteamsapp {
      padding-left: 20px;

      @media (max-width: $ms-screen-max-xl) {
        display: none;
      }
    }

    .item-icon-govteamsapp {
      max-width: 64px;
      height: auto;

      @media (max-width: $ms-screen-max-xl) {
        width: 32px;
        height: 32px;
      }
    }

    .item-icon {
      @extend .ms-BrandIcon--icon96;
      background-size: contain;
      background-repeat: no-repeat;
      max-width: 64px;
      height: auto;

      @media (max-width: $ms-screen-max-xl) {
        width: 32px;
        height: 32px;
      }

      &.item-icon-teams {
        @extend .ms-BrandIcon--teams;
      }

      &.item-icon-sharepoint {
        @extend .ms-BrandIcon--sharepoint;
      }
    }
  }
}

.dashboard-status {
  @include ms-fontSize-s;
  text-align: center;

  @media (min-width: 768px) {
    @include ms-fontSize-mPlus;
    float: right;
  }

  td {
    line-height: 1;
    border-left: 1px solid $ms-color-neutralQuaternary;
    padding: 0 30px;

    &:last-child {
      padding-right: 0;
    }

    @media (max-width: $ms-screen-min-md) {
      padding: 0 20px;
    }

    &:first-child {
      border-left: none;
      padding-left: 0;
    }
  }

  .status-number {
    @include ms-fontWeight-bold;
    font-size: 36px;
    margin-bottom: 10px;
    height: 36px;
  }
}

.dashboard-stats {
  text-align: center;
  background-color: $ms-color-white;
  margin-top: $spacing;
  padding: $spacing * 0.6;

  .stats-item {
    border-bottom: 1px solid $ms-color-neutralLight;
    margin-bottom: $spacing * 0.2;
    padding-bottom: $spacing * 0.2;

    .stats-number {
      @include ms-fontWeight-bold;
      font-size: 34px;
      color: $govTeams-blueDark;
    }
  }
}

.dashboard-profileSettings-menu {
  padding: $spacing * 0.4;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 5px;
    }
  }

  a,
  button,
  .ms-Link {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.dashboard {
  @media (min-width: $ms-screen-min-xl) {
    width: 100%;
    display: table;
    table-layout: fixed;

    .dashboard-content {
      display: table-row;

      .dashboard-left {
        width: 300px;
        display: table-cell;
        vertical-align: top;

        & + .dashboard-right {
          padding-left: $spacing;
        }
      }

      .dashboard-right {
        display: table-cell;
        vertical-align: top;
      }
    }
  }
}

.dashboard-profile-panelPersona {
  cursor: pointer;
  flex: 1;

  @media (min-width: 768px) {
    margin-bottom: 0 !important;
  }
}

.dashboard-profile {
  margin-bottom: $spacing;
  background-color: $ms-color-white;
  padding-bottom: $spacing * 0.4;
  position: relative;

  @media (min-width: $ms-screen-min-xl) {
    width: 300px;
    margin-bottom: 0;
  }

  .dashboard-profileSettings {
    display: inline-block;
    position: absolute;
    top: $spacing * 0.4;
    right: $spacing * 0.4;
    z-index: 5;

    .ms-Button-icon {
      font-size: 22px;
      color: $ms-color-themePrimary;
    }
  }

  .dashboard-persona {
    @include ms-clearfix;
    height: auto !important;
    text-align: center;
    display: block;
    padding-top: $spacing * 0.6;

    .ms-Persona-coin {
      display: inline-block;
      margin-bottom: $spacing * 0.4;
    }

    .ms-Persona-image {
      margin-right: 0;
    }

    .ms-Persona-initials {
      line-height: 120px;
    }

    .ms-Persona-details {
      text-align: center;
      display: inline-block;
      padding: 0;
      flex-direction: unset;
      justify-content: unset;
    }

    .ms-Persona-tertiaryText {
      @include ms-fontSize-m;
    }
  }

  .dashboard-profileSection {
    .dashboard-left & {
      padding: 0 ($spacing * 0.6);
    }

    h3 {
      @include ms-fontSize-l;
      position: relative;

      .ms-Button--action {
        height: auto;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .profile-secondary {
      .profile-edit {
        margin-top: $spacing * 0.4;
        text-align: center;
      }
    }
  }

  .dashboard-profileFooter {
    text-align: center;
    padding-top: $spacing * 0.4;

    .dashboard-profileFooter-bottom {
      min-height: 20px;
      border-top: 1px solid $ms-color-neutralLight;
      padding-top: $spacing * 0.4;
    }
  }

  // .profile-statusPanel {
  //   color: $ms-color-white;
  //   background: $ms-color-themePrimary;
  //   box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
  //   margin: ($spacing * 0.6) 0;
  //   padding: ($spacing * 0.8) ($spacing * 0.8) 0;
  //   position: relative;

  //   & + .dashboard-profileSection > hr {
  //     display: none;
  //   }

  //   .profile-statusPanel-close {
  //     position: absolute;
  //     top: 10px;
  //     right: 10px;
  //   }

  //   .profile-statusPanel-text {
  //     text-align: center;

  //     p:first-child {
  //       @include ms-fontSize-xl;
  //     }
  //   }

  //   .profile-statusPanel-checklist {
  //     list-style: none;
  //     padding: 0;
  //     margin: 0 0 20px 0;

  //     li {
  //       margin-bottom: 20px;
  //       position: relative;

  //       &:before {
  //         content: '';
  //         border-left: 1px solid $ms-color-white;
  //         height: 50px;
  //         display: block;
  //         position: absolute;
  //         top: 0;
  //         left: 16px;
  //       }

  //       &:last-child:before {
  //         display: none;
  //       }
  //     }

  //     i {
  //       font-size: 21px;
  //       line-height: 32px;
  //       text-align: center;
  //       color: $ms-color-themePrimary;
  //       background-color: $ms-color-white;
  //       box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
  //       width: 32px;
  //       height: 32px;
  //       border-radius: 50%;
  //       margin-right: 15px;
  //       vertical-align: -5px;
  //       position: relative;
  //       z-index: 5;
  //     }

  //     [data-icon-name='RightDoubleQuote'] {
  //       padding-top: 6px;
  //       vertical-align: -11px;
  //     }

  //     [data-icon-name='StatusCircleCheckmark'] {
  //       font-size: 32px;
  //       line-height: 29px;
  //       color: $ms-color-white;
  //       background-color: $ms-color-greenLight;
  //       vertical-align: -8px;
  //     }
  //   }

  //   .profile-statusPanel-footer {
  //     text-align: center;
  //     background-color: $ms-color-themePrimary;
  //     padding-bottom: $spacing * 0.8;
  //     position: relative;
  //     z-index: 5;

  //     .ms-Button--action {
  //       color: $ms-color-themePrimary;
  //       background-color: $ms-color-white;
  //       border-radius: 20px;
  //       padding-left: 15px;
  //       padding-right: 15px;
  //     }
  //   }

  //   .profile-statusPanel-beak {
  //     box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
  //     width: 18px;
  //     height: 18px;
  //     background-color: $ms-color-themePrimary;
  //     position: absolute;
  //     bottom: -9px;
  //     left: 50%;
  //     transform: translateX(-50%) rotate(45deg);
  //     z-index: 1;
  //   }
  // }

  .profile-contactTable {
    width: 100%;
    table-layout: fixed;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      padding: 0 10px 10px 0;

      i {
        font-size: 18px;
      }

      &:first-child {
        width: 30px;
      }
    }

    tr:last-child td {
      padding-bottom: 0;
    }
  }

  .profile-toggle {
    height: auto;

    i {
      margin-left: 10px;
    }
  }
}

.dashboard-communities {
  background-color: $ms-color-white;
  padding: $spacing * .6  $spacing;
  margin-bottom: $spacing;

  @media (max-width: $ms-screen-max-lg) {
    padding: $spacing * 0.6;
  }

  .dashboard-communitiesHeader {
    margin-bottom: 20px;

    @media (min-width: $ms-screen-min-md) {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      margin-bottom: 10px;
    }
  }

  .govTeams-ActionButtonAlt {
    margin-left: 20px;
  }

  .dashboard-communitiesUtils {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;;
    margin-bottom: 30px;

    @media (min-width: $ms-screen-min-md) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ms-Button--icon {
      i {
        color: $ms-color-themePrimary;
      }

      @media (max-width: $ms-screen-max-md) {
        i {
          font-size: 26px;
        }
      }
    }

    .govTeams-actionButtonAlt {
      margin-left: $spacing * 0.2;
    }
  }

  h3 {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (max-width: $ms-screen-max-md) {
      font-size: 20px;
      margin-bottom: 0;
    }

    .headingInfo {
      @include ms-fontSize-m;
      color: $ms-color-themePrimary;
      margin-left: 10px;
    }
  }
}

.dashboard-secondary {
  background-color: $ms-color-white;
  margin-top: $spacing;
  padding: $spacing * 0.6;

  .dashboard-status {
    margin: 0 auto;
  }
}

.dashboard-secondary-twoCols {
  @include ms-Grid;
  margin: 0 (-$spacing * 0.5) 0;

  & > div {
    @include ms-Grid-row;

    & > div {
      @include ms-Grid-col;
      @include ms-lg12;
      @include ms-md6;
      @include ms-sm12;
      padding: 0 ($spacing * 0.5);
    }
  }
}

.govTeams-recentDocuments {
  background-color: $ms-color-white;
  padding: $spacing;

  @media (max-width: $ms-screen-max-lg) {
    padding: $spacing * 0.6;
  }

  h2 {
    @include ms-fontSize-xxl;
    display: inline-block;
  }

  .ms-Pivot {
    margin-bottom: 20px;
  }

  .recentDocument-item {
    border-bottom: 1px solid $ms-color-neutralLight;
    padding: 20px 0;
    position: relative;

    &:first-child {
      padding-top: 0;

      .recentDocument-item-left {
        top: 0;
      }
    }

    p {
      margin-bottom: 0;
    }

    .text-meta {
      color: $ms-color-neutralSecondary;
    }

    .recentDocument-item-left {
      position: absolute;
      top: 20px;
      left: 20px;

      i {
        font-size: 24px;
      }
    }

    .recentDocument-item-right {
      padding-left: 60px;

      a {
        @include ms-fontWeight-semibold;
      }
    }
  }
}

.govTeams-news {
  background-color: $ms-color-white;
  margin-top: $spacing;
  padding: $spacing * 0.2;

  .quicklinksError {
    text-align: center;
  }

  h3 {
    @include ms-fontSize-xl;
    @include ms-fontWeight-semilight;
    text-align: center;
    position: relative;
  }

  .news-secondary {
    .news-open {
      margin-top: $spacing * 0.4;
      text-align: center;
    }
  }

  .ms-Button--action {
    height: auto;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: $spacing * 0.4;

      > div {
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
}

.govTeams-quickLinks {
  background-color: $ms-color-white;
  margin-top: $spacing;
  padding: $spacing * 0.6;

  .quicklinksError {
    text-align: center;
  }

  h2 {
    @include ms-fontSize-xl;
    @include ms-fontWeight-semilight;
    text-align: center;
  }


  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: $spacing * 0.4;

      > div {
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
}

.govTeams-organisationLeaderboard {
  background-color: $ms-color-white;
  margin-top: $spacing;
  padding: $spacing * 0.6;

  .dashboard-leaderboardFooter {
    min-height: 20px;
    text-align: center;

    .leaderboard-toggle {
      height: auto;

      i {
        margin-left: 10px;
      }
    }
  }

  h2 {
    @include ms-fontSize-xl;
    @include ms-fontWeight-semilight;
  }

  .organisationLeaderboard-item {
    position: relative;

    &:last-child {
      hr {
        display: none;
      }
    }

    h3 {
      @include ms-fontWeight-bold;
      @include ms-fontSize-mPlus;
      margin-top: 0;
      margin-bottom: 5px;
    }

    .item-number {
      @include ms-fontWeight-bold;
      font-size: 16px;
      width: 30px;
      height: 30px;
      color: $ms-color-white;
      background: $ms-color-themePrimary;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .item-text {
      padding-top: 5px;
      padding-left: 45px;
    }
  }
}

.communityDashboard-section {
  .communityDashboard-sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .communityDashboard-sectionTools {
      display: flex;
      align-items: center;
    }

    .communityAction {
      &:focus {
        color: $ms-color-themePrimary;
      }
    }
  }

  .communityAction {
    @include ms-fontWeight-semibold;
    color: $ms-color-themePrimary;
    // float: right;

    i {
      margin-left: 10px;
    }
  }

  hr {
    clear: both;
  }

  .communityDashboard-section-favourites {
    background-color: $ms-color-neutralLighterAlt;
    padding: $spacing;
    position: relative;
    margin: 15px 0;

    .sectionText {
      font-size: $ms-font-size-l;
      text-align: center;

      @media (min-width: $ms-screen-min-md) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      i {
        font-size: $ms-font-size-xl;
        vertical-align: -3px;
        margin: 0 0.125em;
      }
    }
  }
}

.communityAction-mobile {
  @media (min-width: $ms-screen-min-md) {
    display: none !important;
  }
}

.communityGrid {
  border-top: 1px solid $ms-color-neutralLight;

  .ms-Spinner {
    padding: 45px;
  }
}

.community-ListGrid {
  height: 270px;
  overflow: hidden;

  &.noCommunities,
  &.expand {
    height: auto;
  }
}

.ms-Fabric.is-focusVisible {
  .ms-ListGrid-tile:focus:after {
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    box-sizing: border-box;
    border: 1px solid $ms-color-white;
  }
}

.communityToggle {
  margin-bottom: 30px;
}

.communityList {
  .columnIcon {
    font-size: 16px;
    text-align: center;
    padding: 0 8px;
  }

  .tag {
    @include ms-fontWeight-regular;
    font-size: inherit;
    color: inherit;
    background-color: $ms-color-neutralLight;
    line-height: 1;
    display: inline-block;
    padding: 4px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  i[data-icon-name='FavoriteStar'],
  i[data-icon-name='FavoriteStarFill'] {
    margin-top: -16px;
  }

  .ms-DetailsRow-cell {
    padding-bottom: 0 !important;
  }
}

.pivotLinks {
  @include ms-clearfix;
  clear: both;

  .ms-Pivot {
    background: $ms-color-neutralLight;
    margin-bottom: 30px;

    .ms-Pivot-link {
      margin-right: 0;
      padding: 0 20px;

      &:before {
        left: 0;
      }
    }
  }
}

.ms-DetailsList {
  .sponsoredBadge {
    @include ms-fontSize-s;
    @include ms-fontWeight-semibold;
    background-color: $ms-color-themePrimary;
    color: $ms-color-white;
    line-height: 20px;
    border-radius: 20px;
    margin-left: 8px;
    padding: 0 8px 2px;
  }
}
