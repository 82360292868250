﻿.govTeams-announcements {
    background-color: rgb(237, 237, 237) !important;

    &-0 {
        background-color: rgb(180, 211, 238) !important;
    }

    &-3 {
        background-color: rgb(253, 214, 165) !important;
    }

    &-1 {
        background-color: rgb(243, 211, 214) !important;
    }
}
