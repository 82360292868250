/**
 * Community card custom components
 */
/**
 * Overrides to Office Fabric UI List component to implement custom styles
 */
.ms-ListGrid {
  margin-left: -15px;
  margin-right: -15px;

  .ms-List-page {
    overflow: hidden;
    font-size: 0;
  }

  .ms-List-surface {
    position: relative;
  }

  .ms-ListGrid-tile {
    min-height: 230px;
    text-align: center;
    outline: none;
    position: relative;
    float: left;
  }

  .ms-ListGrid-sizer {
    padding-bottom: 260px;
    height: 0;
  }

  .msListGrid-padder {
    position: absolute;
    left: 15px;
    top: 15px;
    right: 15px;
    bottom: 15px;
  }

  .ms-DocumentCard {
    max-width: none;
    min-width: 0;
    background-color: $ms-color-neutralLighterAlt;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &:hover:after {
      border: none;
    }

    .ms-DocumentCardTitle {
      height: 3.8em;
      font-weight: 600;
      padding: 20px;
    }

    .ms-DocumentCardActions {
      background-color: $ms-color-neutralLight;
      height: auto;
      text-align: center;
      padding: 12px 4px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      :disabled {
        display: none;
      }

      .ms-DocumentCardActions-action {
        display: inline-block;
        margin-right: 0;
        float: none;

        a {
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        .ms-Button--icon.disabled {
          i {
            color: $ms-color-neutralQuaternary;
          }
        }

        .teams-link {
          @extend .ms-BrandIcon--teams;
          @extend .ms-BrandIcon--icon16;
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: 7px 8px;
        }

        .sharepoint-link {
          @extend .ms-BrandIcon--sharepoint;
          @extend .ms-BrandIcon--icon16;
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: 7px 8px;
        }

        .ms-Button--icon {
          background-color: $ms-color-white;
          width: 30px;
          height: 30px;
          line-height: 0;
          border-radius: 50%;
          margin-right: 4px;
          padding: 0;

          &.is-disabled {
            display: none;
          }

          i {
            font-size: 18px;
          }

          i[data-icon-name='Info'] {
            font-size: 22px;
            padding-top: 1px;
            padding-left: 1px;
          }
        }
      }
    }
  }
}

.communityCard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  padding: 0 8px;

  .card-header-left {
    display: flex;
    align-self: flex-start;
  }

  .communityTypeBadge,
  .sponsoredBadge {
    @include ms-fontSize-s;
    @include ms-fontWeight-semibold;
    background-color: $ms-color-white;
    width: 8rem;
    line-height: 20px;
    border-radius: 4px;
    padding: 0 8px 2px;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .sponsored {
    @include ms-fontWeight-semibold;
    color: $ms-color-white;
    font-size: 28px;
  }

  .card-header-right {
    display: flex;
    align-items: center;
  }

  .ms-Button {
    color: $ms-color-white;
    line-height: 1.9em;
    height: 38px;
    width: auto;
    min-width: 0;
    background: transparent;
    padding: 3px 0;

    &:hover,
    &:focus {
      color: $ms-color-white;
      background: transparent;
    }

    i {
      font-size: 20px;
      margin: 0 2px;
    }

    .ms-Button-textContainer {
      font-size: $ms-font-size-s-plus;
      border: 0;
    }
  }

  .ms-Button--icon {
    color: $ms-color-white;
    line-height: 1.6;
    font-size: 16px;
  }
}

.communityCard-description {
  @include ms-fontSize-m;
  padding-left: 10px;
  padding-right: 10px;
  word-wrap: break-word;
}
