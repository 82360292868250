.govTeams-carousel {
  background-color: #193e6d;
  margin-bottom: 30px;
  padding: 30px 50px 40px;

  &.isLoading {
    background-color: #fff;
  }

  .slick-slider {
    padding: 0 0 15px;
  }

  .slideCard {
    padding: 0 20px;

    &.slideTheme-light {
      .slideCard-wrapper {
        background-color: #f1f4f9;

        .slideCard-image .slideCard-headline {
          color: #193e6d;
        }
      }
    }

    .slideCard-wrapper {
      background-color: #69728c;
      max-width: 240px;
      height: 215px;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;

      .slideCard-image {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 120px;
        position: relative;

        .slideCard-headline {
          font-size: 14px;
          font-weight: 600;
          color: #f1f4f9;
          position: absolute;
          bottom: 12px;
          right: 12px;
        }
      }

      .slideCard-text {
        flex: 1;
        padding: 12px;
        background-color: #fff;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 120px;

        .slideCard-textWrapper {
          font-size: 12px;
          border-left: 2px solid #193e6d;
          padding-left: 12px;
        }
      }
    }
  }

  .slick-dots {
    li {
      button:before {
        font-size: 0;
        border: 1px solid #fff;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        opacity: 1;
      }

      &.slick-active button:before {
        background-color: #fff;
        opacity: 1;
      }
    }
  }
}
