.ms-DetailsHeader-wrapped > span > .ms-DetailsHeader-cellTitle > .ms-DetailsHeader-cellName {
  flex: auto !important;
  white-space: normal;
  line-height: normal;
  text-overflow: clip;
  align-items: center;
}

.organisation-landing {
  h1 {
    margin-bottom: 40px;
    padding-top: 40px;
  }

  .organisationList {
    .organisationList-item {
      margin-bottom: 20px;
    }

    .organisationList-itemButton {
      @include ms-fontSize-l;
      width: 100%;
      max-width: 350px;
      background: $ms-color-white;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px 0px;
      height: auto;
      padding: 30px 40px 30px 20px;

      i {
        color: $ms-color-themePrimary;
      }

      i[data-icon-name='ChevronRight'] {
        @include ms-fontSize-m;
        position: absolute;
        right: 20px;
      }

      .ms-Button-icon {
        font-size: 36px;
      }
    }
  }
}

.orgManagement-page {
  .page-header {
    background-color: $ms-color-themePrimary;
  }

  .page-cell.managementTools {
    padding-top: 30px;
    margin-top: 40px;
  }

  .orgDomains {
    width: 100%;
    margin-bottom: 30px;

    th {
      background-color: $ms-color-neutralLight;
      padding: 10px 20px;
    }

    td {
      border-bottom: 1px solid $ms-color-neutralLight;
      padding: 10px 20px;
    }
  }

  .licenceAllocation-warning {
    margin-bottom: 30px;
  }

  .licenceAllocation-stats {
    table {
      @include ms-fontSize-l;
      width: 100%;

      td {
        border-bottom: 1px solid $ms-color-neutralLight;
        padding: 15px 0 5px 0;

        & + td {
          @include ms-fontWeight-bold;
          text-align: right;
          padding-left: 10px;
        }
      }
    }
  }

  .licenceAllocation {
    @include ms-Grid;

    .row {
      @include ms-Grid-row;
      margin: 0 -20px;
    }

    .licenceAllocation-chart,
    .licenceAllocation-stats {
      @include ms-Grid-col;
      @include ms-sm12;
      @include ms-md12;
      @include ms-lg6;
      @include ms-xl6;

      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.orgMembersManagement-page {
  padding-top: 20px;

  .topSection {
    margin-bottom: 30px;

    @media (min-width: $ms-screen-min-xl) {
      width: 50%;
    }
  }

  .filterList {
    padding: 30px;

    .filterList-footer {
      @media (min-width: $ms-screen-min-lg) {
        width: 60%;
        padding-right: 30px;
      }
    }
  }

  .membersList {
    max-height: 55vh;
    overflow: auto;
  }

  .searchRow {
    margin-bottom: 30px;

    .rowsDropdown-col {
      margin-top: 15px;
    }

    @media (min-width: $ms-screen-min-lg) {
      display: flex;
      justify-content: space-between;

      .searchBox-col {
        width: 100%;
      }

      .rowsDropdown-col {
        margin-top: 0;
        padding-left: 30px;
      }
    }
  }

  .infoMessage {
    margin-bottom: 30px;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.filterList {
  @include ms-Grid;
  background-color: $ms-color-white;
  padding: 30px 0;

  .filterList-row {
    @include ms-Grid-row;
    // margin: 0 -20px;
  }

  .filterList-heading {
    @include ms-Grid-col;
    @include ms-md12;
    @include ms-lg12;
    @include ms-xl2;

    h2 {
      font-size: 21px;
      font-weight: 300;
      color: $ms-color-neutralPrimary;
      margin: 0;
      padding: 0;
      border-bottom: none;
    }
  }

  .filterList-dropdowns {
    @include ms-Grid-col;
    @include ms-md12;
    @include ms-lg10;
  }

  .filterList-dropdowns-row {
    @include ms-Grid-row;
  }

  .filterList-dropdown {
    @include ms-Grid-col;
    @include ms-md12;
    @include ms-lg4;
    @include ms-xl3;
  }

  .filterList-footer {
    @include ms-Grid-col;
    @include ms-sm12;
    margin-top: 30px;

    @media (min-width: $ms-screen-min-xl) {
      text-align: right;
    }

    .ms-Button--primary {
      margin-left: 10px;
    }
  }
}

.meta-contactInfo {
  @include ms-fontSize-l;

  &:hover,
  &:focus {
    color: inherit;

    i {
      color: inherit;
    }
  }

  i {
    font-size: 21px;
    color: inherit;
    line-height: 1;
    width: 40px;
    height: 40px;
    background-color: $ms-color-neutralLight;
    border-radius: 50%;
    margin-right: 10px;
    padding: 10px;
    vertical-align: -6px;
  }
}

/****************************************************************
 *
 * CSS Percentage Circle
 * Adapted from work by: Andre Firchow
 *
*****************************************************************/

// Compass utilities

// VARS
$circle-width: 0.09em;
$circle-width-hover: 0.04em;

// colors default
$primary-color: rgb(0, 120, 212);
$secondary-color: #ccc;
$bg-color: #f5f5f5;

// CIRCLE
// classes 2 extend
.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

.pie {
  position: absolute;
  border: $circle-width solid $primary-color;
  width: 1 - (2 * $circle-width);
  height: 1 - (2 * $circle-width);
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  transform: rotate(0deg);
}

.pie-fill {
  transform: rotate(180deg);
}

// main
.c100 {
  *,
  *:before,
  *:after {
    box-sizing: content-box;
  }

  position: relative;
  font-size: 200px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0 0.1em 0.1em 0;
  background-color: $secondary-color;

  // center circle to its parent
  &.center {
    float: none;
    margin: 0 auto;
  }

  // centered value inside circle
  > span {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 1.7em;
    width: 5em;
    font-size: 0.2em;
    line-height: 0.7em;
    display: block;
    text-align: center;
    white-space: nowrap;

    .percentage {
      font-size: 40px;
      color: $primary-color;
      display: block;

      &.percentageMax {
        color: #e3008c;
      }
    }

    .percentageLabel {
      font-size: 12px;
      color: $ms-color-black;
      text-transform: uppercase;
      letter-spacing: 0.075em;
    }
  }

  // background inside the circle
  &:after {
    position: absolute;
    top: $circle-width;
    left: $circle-width;
    display: block;
    content: ' ';
    border-radius: 50%;
    background-color: $ms-color-white;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
  }

  // the slice (mask)
  .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
  }

  // circle to show the status
  .bar {
    @extend .pie;
  }

  // loop to create all needed elements automatically
  @for $j from 51 through 100 {
    &.p#{$j} .slice {
      @extend .rect-auto;
    }

    &.p#{$j} .bar:after {
      @extend .pie-fill;
    }

    &.p#{$j} .fill {
      @extend .pie;
      @extend .pie-fill;
    }
  }

  @for $j from 70 through 94 {
    &.p#{$j} .bar:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$circle-width;
      border-radius: inherit;
      background: rgb(0, 120, 212);
      background: linear-gradient(
        -(calc(($j * 1.05)/30) * 180deg),
        rgba(0, 120, 212, 1) 0%,
        rgba(79, 107, 237, 1) 56%,
        rgba(227, 0, 140, 1) 100%
      );
    }
  }

  @for $j from 95 through 100 {
    &.p#{$j} .bar:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$circle-width;
      border-radius: inherit;
      background: rgb(0, 120, 212);
      background: linear-gradient(
        -550deg,
        #0078d4 0%,
        #4f6bed 20%,
        #e3008c 90%
      );
    }
  }

  // loop to rotate all 100 circles
  @for $j from 1 through 100 {
    &.p#{$j} .bar {
      transform: rotate((calc(360/100) * $j) + deg);
    }
  }
}
