.govTeams-account {
  @include ms-Grid;

  .govTeams-account-row {
    @include ms-Grid-row;
  }

  .govTeams-account-col {
    @include ms-Grid-col;
    @include ms-sm12;
    @include ms-md12;
    @include ms-lg8;
    @include ms-xl7;
    @include ms-xxl5;

    &.col-wide {
      @include ms-xxl6;
    }
  }

  h1 + hr + form {
    margin-top: 30px;
  }
  
  h2 {
    margin-bottom: 10px;
  }

  .govTeams-actionButton-pw {
    font-weight: $ms-font-weight-semibold !important;
    color: $ms-color-themePrimary;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    .ms-Button-icon {
      font-size: 18px;
    }
  }

  .govTeams-delve {
    & > hr {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    h2 {
      margin-bottom: 0;
    }

    h3 {
      @include ms-fontWeight-regular;
      margin-bottom: 10px;
    }

    .govTeams-delve-section {
      margin-bottom: 30px;

      p {
        margin-bottom: 0;
      }
    }

    .ms-Button--primary {
      margin-left: 0 !important;
      padding-right: 25px;

      i {
        font-size: $ms-font-size-s;
        position: absolute;
        top: 8px;
        right: 5px;
      }
    }
  }
}
