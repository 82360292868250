/**
 * Community tools
 */
.govTeams-communityTools {
  @include ms-clearfix;
  
  ul.toolsList {
    list-style: none;
    margin: 10px 0 0;
    padding: 0;

    li {
      margin-bottom: 0px;
    }

    .toolsList-itemTeams {
      i {
        @extend .ms-BrandIcon--teams;
        @extend .ms-BrandIcon--icon48;
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: 7px 8px;
        font-size: 0;
        width: 40px;
        height: 40px;
      }
    }

    .toolsList-itemSharePoint {
      i {
        @extend .ms-BrandIcon--sharepoint;
        @extend .ms-BrandIcon--icon48;
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: 8px 8px;
        font-size: 0;
        width: 40px;
        height: 40px;
      }
    }
  }

  .ms-Button--default {
    text-align: left;
    height: 55px;
    line-height: 1.6;
    min-width: 0;
    background: transparent;
    padding: 0 4px;

    &.disabled {
      i {
        color: $ms-color-neutralQuaternary;
      }
    }

    &:hover {
      background-color: transparent;
    }

    i {
      font-size: 19px;
      width: 40px;
      height: 40px;
      background-color: $ms-color-white;
      box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      margin: 0 10px 0 0;
      padding: 11px;
    }
    
    .ms-Button-label {
      @include ms-fontWeight-regular;
      @include ms-fontSize-l;
    }
  }

  .ms-Button--primary {
    width: 100%;
    white-space: nowrap;
  }
}