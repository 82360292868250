.govTeams-actionButtonAlt {
    padding-right: 25px !important;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active > .ms-Button-flexContainer {
        position: static;
    }

    i {
        font-size: $ms-font-size-s;
        position: absolute;
        top: 8px;
        right: 5px;
    }
}

.govTeams-actionButton {
    color: $ms-color-themePrimary;
    padding: 0 !important;

    &.icon-l .ms-Button-icon {
        font-size: 1em;
    }

    .ms-Button-label {
        @include ms-fontWeight-semibold;
        color: $ms-color-themePrimary !important;
    }

    .ms-Button-icon {
        font-size: 0.8em;
        margin-left: 0;
    }

    [data-icon-name='Info'] {
        margin-left: 10px;
    }
}

.govTeams-delveButton {
    border-radius: 20px !important;

    &:hover,
    &focus {
        text-decoration: none;
    }
}

.ms-Button--command {
    &:hover {
        text-decoration: none;
    }
}

.ms-Button--icon.is-disabled {
    i {
        background-color: transparent;
        border-color: transparent;
        color: $ms-color-neutralTertiary;
    }
}

.ms-Button--action {
    @include ms-fontWeight-semibold;
}
